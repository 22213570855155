import { SxProps } from "@mui/material";

export const Title: SxProps = {
  fontWeight: 700,
  fontSize: "32px",
  lineHeight: "48px",
  color: (theme: any) => theme.palette.neutral["800"],
};

export const SubTitle: SxProps = {
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  color: (theme: any) => theme.palette.neutral["800"],
  marginTop: "13px",
};

export const badge: SxProps = {
  borderRadius: "24px",
  px: 1,
  py: "4px",
  marginLeft: "14px",
  fontSize: "14px",
  fontWeight: 400,
  backgroundColor: (theme: any) => theme.palette.primary["100"],
  color: (theme: any) => theme.palette.primary["500"],
};

export const pagination: SxProps = {
  borderTop: "0px",
};

export const editButton: SxProps = {
  padding: 0, 
  minWidth: 'auto'
};

export const headerSearchStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '16px'
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  searchContainer: {
    flex: 1,
  },
  searchField: {
    '& .MuiInputBase-root': {
      height: '32px',
      fontSize: '14px',
      backgroundColor: (theme: any) => theme.palette.background.paper,
      '&:hover': {
        backgroundColor: (theme: any) => theme.palette.action.hover,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: (theme: any) => theme.palette.divider,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: (theme: any) => theme.palette.primary.main,
      },
    },
    '& .MuiInputBase-input': {
      padding: '4px 8px',
      '&::placeholder': {
        fontSize: '14px',
        opacity: 0.7,
      },
    },
  }
};