import {
  Box,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  switchBorder,
  label,
  groupingOrders,
  textNumberField,
} from "../../queueSetting.style";
import QueueSettingModel from "../../../../../models/status/queueSettingModel";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import QueueGroupModel from "../../../../../models/queue/queueGroupModel";
import formatAttribute from "../../../../../common/formatAttribute";
import ValidationError from "../../../../../models/status/validationError";
import { isChanged } from "../../../../../redux/slices/unsavedChangesDataSlice";
import { useDispatch } from "react-redux";
import OrderGroupingModel from "../../../../../models/queue/orderGroupingModel ";
import { getOrderGroupings } from "../../../../../core/services/status/statusService";
import { getQueueGroups } from "../../../../../core/services/queueGroups/queueGroupsService";
import GroupingOrderList from "./components/groupingOrderList";
import { tableRow } from "../../../../../common/styles/tableStyle.style";
import MenuIcon from "@mui/icons-material/Menu";
import { DraggableProvided } from "react-beautiful-dnd";
interface GeneralTabContentProps {
  readonly queueSetting: QueueSettingModel | undefined;
  readonly setQueueSetting: React.Dispatch<
    React.SetStateAction<QueueSettingModel | undefined>
  >;
  isLoaded: boolean;
  readonly validationErrors: ValidationError[];
  requiredProps: string[];
  setValidationErrors: any;
}
export default function GeneralTabContent(
  generalTabContentProps: Readonly<GeneralTabContentProps>
) {
  const {
    queueSetting,
    setQueueSetting,
    isLoaded,
    validationErrors,
    requiredProps,
    setValidationErrors,
  } = generalTabContentProps;
  const [queueGroups, setQueueGroups] = useState<QueueGroupModel[]>([]);
  const [orderGroupings, setOrderGroupings] = useState<OrderGroupingModel[]>(
    []
  );
  const UNSELECTED_ORDER_GROUPING = 0;
  const dispatch = useDispatch();

  // load lookup data
  useEffect(() => {
    getAllQueueGroups();
    getAllOrderGroupings();
  }, []);

  // bind order groupings to the filter settings
  useEffect(() => {
    if (!queueSetting) return;
    var items = orderGroupings?.filter(isSelectedOrderGrouping);
    items.forEach((item, index) => {
      item.ordinal = index + 1;
    });
    setQueueSetting({
      ...queueSetting,
      orderGroupings: [
        ...(items ?? []),
      ],
    });
  }, [orderGroupings]);

  // bind filter settings to the order groupings after the intial loading
  useEffect(() => {
    if (!queueSetting?.orderGroupings) return;
    bindOrderGroupings(orderGroupings);
    setOrderGroupings([...orderGroupings]);
  }, [isLoaded]);
 
  const isSelectedOrderGrouping = (item: OrderGroupingModel) => {
    return !!item.selected;
  };

  const getAllOrderGroupings = async () => {
    const groups = await getOrderGroupings();
    if(queueSetting){
      bindOrderGroupings(groups);
    }
    setOrderGroupings([...groups]);
  };

  function bindOrderGroupings(groups: OrderGroupingModel[]) {
    groups.forEach((group) => {
      var queueSettingItem = queueSetting?.orderGroupings?.find(
        (item) => item.id === group.id
      );
      group.selected = queueSettingItem ? true : false;
      group.ordinal = queueSettingItem ? queueSettingItem.ordinal : Number.MAX_SAFE_INTEGER;
    });
    groups.sort((a, b) => a.ordinal - b.ordinal);
  }

  const getAllQueueGroups = async () => {
    const groups = await getQueueGroups();
    groups.sort((a, b) => a.queueGroupName.localeCompare(b.queueGroupName));
    setQueueGroups([...groups]);
  };

  const handleSyncChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (queueSetting) {
      queueSetting.isSynced = checked;
      setQueueSetting({ ...queueSetting });
      dispatch(isChanged(true));
    }
  };

  const handleOrderGroupingSelectChanged = (row: OrderGroupingModel) => {
    if (!queueSetting) return;
    setOrderGroupings((prevItems) => {
      let updatedItems = prevItems.map((item) =>
        item.id === row.id
          ? {
              ...item,
              selected: !item.selected,
            }
          : item
      );
      return updatedItems;
    });
    dispatch(isChanged(true));
  };

  const handleNumberOfParts = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (queueSetting) {
      setQueueSetting({
        ...queueSetting,
        queueConfig: {
          ...queueSetting?.queueConfig,
          numberOfParts: +e.target.value,
        },
      });
      dispatch(isChanged(true));
    }
  };
  const onGroupChanged = useCallback(
    (event: SelectChangeEvent) => {
      if (queueSetting) {
        queueSetting.queueGroupId = +event.target.value;
        setQueueSetting({ ...queueSetting });
        dispatch(isChanged(true));
      }
      const queueGroupIdProp = validationErrors.find(
        (p) => p.propName === requiredProps[0]
      );

      if (queueGroupIdProp) queueGroupIdProp.isValid = true;
      setValidationErrors([...validationErrors]);
    },
    [queueSetting]
  );

  const handleDrop = (items: any) => {
    if (!Array.isArray(items) || !items.every(item => 'id' in item && 'selected' in item)) return;
      const updatedItems = items as OrderGroupingModel[];
      
      updatedItems.forEach((item, index) => {
        //item.ordinal = index + 1;
      });
      setOrderGroupings([...updatedItems]);
      dispatch(isChanged(true));
    };

  const orderGroupingItemTemplate = (
    row: OrderGroupingModel,
    provider: DraggableProvided
  ) => {
    return (
      <Box
        sx={{ ...switchBorder, display: "flex", justifyContent: "space-between", alignItems: "center" }}
        {...provider.draggableProps}
        ref={provider.innerRef}
        id={`${row.fieldName}-container`}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
            sx={{...tableRow, marginTop: "10px"}}
            {...provider.dragHandleProps}
            id={`${row.fieldName}-menu-handler`}
            data-testid={`${row.fieldName}-menu-handler`}
            >
            <MenuIcon />
            </Typography>
          <label htmlFor={`${row.fieldName}-switch`}>
        <Typography
          sx={{ ...tableRow, textAlign: "left", marginLeft: "5px" }}
          data-auto-test-id={`${row.fieldName}-switch`}
        >
          Orders under the same {row.displayName} are displayed together
        </Typography>
          </label>
        </Box>
        <Switch
          id={`${row.fieldName}-switch`}
          data-testid={`${row.fieldName}-switch`}
          sx={{ pointerEvents: "auto" }}
          checked={!!row.selected}
          onChange={() => handleOrderGroupingSelectChanged(row)}
          inputProps={{ "aria-label": "controlled" }}
          color={"success"}
        />
      </Box>
    );
  };

  return (
    <Box>
      <Box sx={{ marginTop: "30px" }}>
        <Typography sx={label}>Sync Orders from MR8 to Next Gen</Typography>
        <Box sx={switchBorder}>
          <Typography> Orders are Synced</Typography>
          <Switch
            data-testid={"sync-order"}
            checked={queueSetting?.isSynced ?? false}
            onChange={handleSyncChange}
            sx={{ pointerEvents: "auto" }}
            inputProps={{ "aria-label": "controlled" }}
            color={"success"}
          />
        </Box>
      </Box>
      <Box sx={ groupingOrders }>
        <Typography sx={label}>Function Group</Typography>
        <Box>
          <Select
            fullWidth
            size="small"
            value={queueSetting?.queueGroupId?.toString() ?? "0"}
            sx={{ pointerEvents: "auto" }}
            onChange={onGroupChanged}
            data-auto-test-id="select-queue-group-drop-down"
          >
            <MenuItem key="0" disabled value="0">
              Select
            </MenuItem>
            {queueGroups.map((group) => {
              return (
                <MenuItem
                  key={group.queueGroupName}
                  value={group.queueGroupId}
                  data-auto-test-id={
                    formatAttribute(group.queueGroupName ?? "") + "-menu-item"
                  }
                >
                  {group.queueGroupName}
                </MenuItem>
              );
            })}
          </Select>
          <Typography
            data-auto-test-id="threshold-error-text"
            component="span"
            color={"error"}
            data-testid="group-error"
          >
            {validationErrors.find((p) => p.propName === requiredProps[0])
              ?.isValid
              ? ""
              : validationErrors.find((p) => p.propName === requiredProps[0])
                  ?.message}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: "30px" }}>
        <Grid
          container
          sx={{ width: "72%" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography sx={label}>Grouping Orders</Typography>
          </Grid>
          <Grid item alignItems="center" display={"flex"}>
            <Grid item>
              <label htmlFor="number-of-parts-text">
                <Typography
                  sx={{ marginRight: "10px;" }}
                  data-auto-test-id="number-of-parts-text"
                >
                  No. of Parts
                </Typography>
              </label>
            </Grid>
            <Grid item>
              <TextField
                type="number"
                size="small"
                id="number-of-parts-text"
                sx={textNumberField}
                value={queueSetting?.queueConfig.numberOfParts || ""}
                onChange={(e) => handleNumberOfParts(e)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <GroupingOrderList
            handleItemsDrop={handleDrop}
            items={orderGroupings}
            itemTemplate={orderGroupingItemTemplate}
          />
        </Grid>
      </Box>
    </Box>
  );

  
}
