import { SxProps } from "@mui/material";

export const statusTitle: SxProps = {
  fontWeight: "700",
  fontSize: "1.5rem",
  lineHeight: "48px",
  color: (theme: any) => theme.palette.neutral["800"],
  paddingLeft: "6px",
  overflowWrap: "anywhere",
};

export const submitButton: SxProps = {
  fontSize: "16px",
  fontWeight: "700",
  marginTop: "40px",
  height: "48px",
  width: "150px",
  color: (theme: any) => theme.palette.common.white,
};

export const switchBorder: SxProps = {
  backgroundColor: (theme: any) => theme.palette.neutral["100"],
  padding: "16px",
  width: "70%",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "6px",
};

export const label: SxProps = {
  fontSize: "1.2rem",
  fontWeight: "400",
};

export const textNumberField: SxProps = {
  width: 100,
  height: 20,
  marginBottom: "20px",
};

export const selectStyle: SxProps = {
  width: "50%",
};

export const closeIcon: SxProps = {
  color: (theme: any) => theme.palette.primary["500"],
  stroke: (theme: any) => theme.palette.primary["500"],
  strokeWidth: 2,
  fontSize: "1.5rem",
  cursor: "pointer",
};

export const groupingOrders: SxProps = {
  marginTop: "30px",
  width: "50%",
};
