import { Box, Card, CardActions, Grid, Typography } from "@mui/material";
import QueueDetailsStyles from "../../../queueDetails.style";
import { useTheme } from "@mui/system";
import UserOrders from "../../../../../models/order/userOrders";

export default function GroupedOrdersCards({
  userOrders,
  ChangeOrderStatus,
}: {
  userOrders: UserOrders;
  ChangeOrderStatus: JSX.Element | null;
}) {
  return (
    <>
      {userOrders && (
        <Box sx={QueueDetailsStyles.ordersBox}>
          <Typography
            mt={1}
            variant="h6"
            sx={[QueueDetailsStyles.userMsg, { marginBottom: "22px" }]}
          >
            Your Current Order
            <CardActions sx={QueueDetailsStyles.actionButtonGrouped}>
              <Grid container justifyContent={"flex-end"}>
                <Grid item>{userOrders && ChangeOrderStatus}</Grid>
              </Grid>
            </CardActions>
          </Typography>

          <Box sx={QueueDetailsStyles.groupsContainer}>
            {userOrders.groups && userOrders.groups.length > 0 && (
              userOrders.groups.map((group, index) => (
              <Box key={index + group.text} sx={QueueDetailsStyles.groupItemContainer}>
                <Typography sx={QueueDetailsStyles.caseNoTxt} component="span">
                {group.text}:{" "}
                </Typography>
                <Typography sx={QueueDetailsStyles.caseNo} component="span">
                {group.value}
                </Typography>
              </Box>
              ))
            )}
              <Box sx={QueueDetailsStyles.groupItemContainer}>
                <Typography sx={QueueDetailsStyles.caseNoTxt} component="span">
                  Number of parts:{" "}
                </Typography>
                <Typography sx={QueueDetailsStyles.partsNo} component="span">
                  {userOrders?.userOrderList?.length} Parts
                </Typography>
              </Box>
          </Box>
          <Grid container sx={{ display: "flex" }}>
            {userOrders.userOrderList?.map((order, index) => (
              <Grid item xs={4} key={order.orderNo}>
                <Card
                  sx={[QueueDetailsStyles.card, QueueDetailsStyles.groupCard]}
                  key={order.orderNo}
                >
                  <Box m={2} width={350} p={1}>
                    {order && (
                      <>
                        <Typography
                          data-auto-test-id="order-no-text"
                          sx={QueueDetailsStyles.userMsg}
                        >
                          Order Number
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={QueueDetailsStyles.currentOrderNo}
                        >
                          <strong data-auto-test-id="order-no">
                            {order.orderNo}
                          </strong>
                        </Typography>
                      </>
                    )}
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
}
