import { Group } from "../models/sideBar/group";
import UploadIcon from "@mui/icons-material/Upload";
import { RolesEnum } from "../models/enums/rolesEnum";
import appConfig from "../settings/appConfig";

export const Links = {
  uploadOrders: "upload",
  home: "/",
  queue: "queue",
  reports: "reports",
  queuesSetup: "queuesSetup",
  queueSetting: "queueSetting",
  groupsSetting: "groupSetting",
  sortQueues: "sortQueues",
  ordersIntegrationMatchedOrders: "ordersIntegration/matched",
  ordersIntegrationMismatchedOrders: "ordersIntegration/mismatched",
};

export const menuItems: Group[] = [
  {
    groupName: "Administration",
    groupItems: [
      {
        id: Links.uploadOrders,
        title: "Upload Orders",
        Icon: <UploadIcon />,
        navigateTo: `/${Links.uploadOrders}`,
        roles: [RolesEnum.Admin],
      },
      {
        id: Links.reports,
        title: "Reports",
        Icon: <UploadIcon />,
        navigateTo: `/${Links.reports}`,
        roles: [RolesEnum.Admin],
      },
      {
        id: Links.queuesSetup,
        title: "Queues Setup",
        navigateTo: `/${Links.queuesSetup}`,
        roles: [RolesEnum.Admin],
      },
      {
        id: Links.queuesSetup,
        title: "Groups Setup",
        navigateTo: `/${Links.groupsSetting}`,
        roles: [RolesEnum.Admin],
      },
    ],
  },
  {
    groupName: appConfig.queuesGroupName,
    groupItems: [],
  },
  ...(appConfig.features?.enableOrdersIntegration ? [
    {
      groupName: appConfig.OrdersIntegration,
      groupItems: [
        {
          id: Links.ordersIntegrationMatchedOrders,
          title: "Matched Orders",
          navigateTo: `/${Links.ordersIntegrationMatchedOrders}`,
          roles: [RolesEnum.Regular, RolesEnum.Admin, RolesEnum.Lead],
        },
        {
          id: Links.ordersIntegrationMismatchedOrders,
          title: "Mismatched Orders",
          navigateTo: `/${Links.ordersIntegrationMismatchedOrders}`,
          roles: [RolesEnum.Regular, RolesEnum.Admin, RolesEnum.Lead],
        },
      ],
    },
  ] : []),
];
