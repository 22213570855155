import PaginationModel from "../../../models/common/paginationModel";
import ResponseModel from "../../../models/common/responseModel";
import OrderGroupingModel from "../../../models/queue/orderGroupingModel ";
import QueueConfigLogModel from "../../../models/queue/queueConfigLogModel";
import QueueConfigLogsRequest from "../../../models/queue/queueConfigLogsRequest";
import QueueSettingModel from "../../../models/status/queueSettingModel";
import Status from "../../../models/status/status";
import appConfig from "../../../settings/appConfig";
import { get, post, put } from "../../http/apiClient";

export const getAllStatuses = async (): Promise<Status[]> => {
  try {
    const response = await get(
      `${appConfig.baseUrl}${appConfig.URLS.Status.GetAll}`
    );
    const status = response as Status[];

    return status;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getOrderGroupings = async (): Promise<OrderGroupingModel[]> => {
  try {
    const response = await get(
      `${appConfig.baseUrl}${appConfig.URLS.Status.GetAllOrderGroupings}`
    );
    return response as OrderGroupingModel[];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getQueueSetting = async (
  statusId: number
): Promise<ResponseModel<QueueSettingModel>> => {
  try {
    const response = await get(
      `${appConfig.baseUrl}${appConfig.URLS.Status.GetQueueSetting}/${statusId}`
    );
    const queueSettingModel = response as ResponseModel<QueueSettingModel>;
    return queueSettingModel;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateQueueSetting = async (
  queueSetting: QueueSettingModel
): Promise<number> => {
  try {
    const response = await put(
      `${appConfig.baseUrl}${appConfig.URLS.Status.UpdateQueueSetting}`,
      queueSetting
    );
    const id = response as number;
    return id;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getQueueConfigLogs = async (
  queueConfigLogsRequest: QueueConfigLogsRequest
): Promise<PaginationModel<QueueConfigLogModel> | null> => {
  try {
    const response = await post(
      `${appConfig.baseUrl}${appConfig.URLS.Status.GetQueueConfigLogs}`,
      queueConfigLogsRequest
    );
    const queueConfigLogsModel =
      response as PaginationModel<QueueConfigLogModel>;
    return queueConfigLogsModel;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
