import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  IconButton
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import ComponentLoader from "../../common/loader/componentLoader";
import SortIcon from "../../common/sortTableIcon/sortIcon";
import { Title, SubTitle, badge, pagination, editButton, headerSearchStyles } from "./queuesSetup.style";
import { tableContainer, tableHeader, tableRow, editColor } from "../../common/styles/tableStyle.style";
import Status from "../../models/status/status";
import { getAllStatuses } from "../../core/services/status/statusService";
import appConfig from "../../settings/appConfig";
import { SortDirection } from "../../models/common/sortTableHeader";

const HeaderSearchField = ({ sortDir,sortField, value, onChange, onSort }: {
  sortDir: SortDirection;
  sortField: number;
  value: string;
  onChange: (value: string) => void;
  onSort: () => void;
}) => (
  <Box sx={headerSearchStyles.container}>
    <Box sx={headerSearchStyles.labelContainer} onClick={onSort}>
      <Typography variant="body1" sx={{ mr: 1 }}>Part Status</Typography>
      <SortIcon itemId={3} sortDir={sortDir} sortField={sortField} />
    </Box>
    <Box sx={headerSearchStyles.searchContainer}>
      <TextField
        fullWidth
        size="small"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Search status..."
        onClick={(e) => e.stopPropagation()}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" sx={{ color: (theme) => theme.palette.text.secondary }} />
            </InputAdornment>
          ),
          endAdornment: value ? (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onChange("");
                }}
                sx={{ p: 0.5 }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
        sx={headerSearchStyles.searchField}
      />
    </Box>
  </Box>
);

export default function QueuesSetupPage() {
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [filteredStatuses, setFilteredStatuses] = useState<Status[]>([]);
  const [currentStatuses, setCurrentStatuses] = useState<Status[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState(0);
  const [sortDir, setSortDir] = useState<SortDirection>("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const tableHeaders = useMemo(
    () => [
    {
      id: 1,
      label: "Synced",
      hasSort: true,
      propName: "isSynced",
      width: "15%",
    },
    { id: 2, label: "Edit", hasSort: false, width: "15%" },
    {
      id: 3,
      label: "Part Status",
      hasSort: true,
      propName: "statusName",
      width: "70%",
    },
    ],
    []
  );

  const queueSetupPageCount = appConfig.queueSetupPageCount;

  const filterStatuses = useCallback((allStatuses: Status[], term: string) => {
    if (!term) return allStatuses;
    return allStatuses.filter(status =>
      status.statusName.toLowerCase().includes(term.toLowerCase())
    );
  }, []);

  const updateCurrentStatuses = useCallback(
    (currentPage: number) => {
      const start = (currentPage - 1) * queueSetupPageCount;
      const end = start + queueSetupPageCount;
      setCurrentStatuses(filteredStatuses.slice(start, end));
    },
    [filteredStatuses, queueSetupPageCount]
  );

  const getStatuses = useCallback(async () => {
    try {
      setIsLoading(true);
      const allStatuses = await getAllStatuses();
      setStatuses(allStatuses);
      setFilteredStatuses(allStatuses);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }, []);

  const applySorting = useCallback( (data: any[], property: number, dir: SortDirection) => {
      const headerName = tableHeaders.find((p) => p.id === property)?.propName;

      if (!headerName) return data;

      const sortedData = [...data].sort((a, b) =>
        String((a as any)[headerName]).localeCompare((b as any)[headerName])
      );
      if (dir === "desc") {
        sortedData.reverse();
      }

      return sortedData;
    },
    [tableHeaders]
  );

  useEffect(() => {
    const newFilteredStatuses = filterStatuses(statuses, searchTerm);
    const sortedFilteredStatuses = applySorting(newFilteredStatuses, sortField, sortDir);
    setFilteredStatuses(sortedFilteredStatuses);
    setPage(1);
  }, [searchTerm, statuses, filterStatuses, sortField, sortDir, applySorting]);

  useEffect(() => {
    updateCurrentStatuses(page);
  }, [updateCurrentStatuses, page, filteredStatuses]);

  useEffect(() => {
    getStatuses();
  }, [getStatuses]);

  const handleChangePage = (event: any, value: number) => {
    event.stopPropagation();
    setPage(value);
  };

  const sortStatuses = (property: number, dir: SortDirection) => {
    const sortedStatuses = applySorting(filteredStatuses, property, dir);

    setFilteredStatuses(sortedStatuses);
    setSortDir(dir);
    setSortField(property);
  };

  const handleToggleSort = (key: number) => {
    const isAsc = key === sortField && sortDir === "asc";
    sortStatuses(key, isAsc ? "desc" : "asc");
  };

  const highlightMatch = (text: string, term: string) => {
    if (!term) return text;

    const parts = text.split(new RegExp(`(${term})`, 'gi'));

    return (
      <>
        {parts.map((part, i) =>
          part.toLowerCase() === term.toLowerCase() ? (
            <Box
              key={i}
              component="span"
              sx={{
                backgroundColor: (theme:any) => theme.palette.primary[100], 
                color: (theme:any) => theme.palette.primary[500] 
              }}
            >
              {part}
            </Box>
          ) : (
            part
          )
        )}
      </>
    );
  };

  const totalNumberOfPages = useMemo(() => {
    return Math.ceil(filteredStatuses.length / queueSetupPageCount);
  }, [filteredStatuses.length, queueSetupPageCount]);

  return (
    <Box>
      <Typography sx={Title}>Queues Setup</Typography>
      <Grid container alignItems="end" sx={{ mb: 2 }}>
        <Grid item>
          <Typography sx={SubTitle}>Active Part Statuses In MR8</Typography>
        </Grid>
        <Grid item>
          <Box sx={badge}>
            {searchTerm ? filteredStatuses.length : statuses.length}
          </Box>
        </Grid>
      </Grid>

      <ComponentLoader isOpen={isLoading}>
        <TableContainer sx={tableContainer} component={Paper}>
          <Table
            data-auto-test-id="remaining-order-table"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {tableHeaders.map((item) => (
                  <TableCell
                    sx={{ ...tableHeader, width: item.width }}
                    key={item.id}
                  >
                    {item.id === 3 ? (
                      <HeaderSearchField
                        sortDir={sortDir}
                        sortField={sortField}
                        value={searchTerm}
                        onChange={setSearchTerm}
                        onSort={() => handleToggleSort(item.id)}
                      />
                    ) : item.hasSort ? (
                      <Grid
                        container
                        alignItems="center"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleToggleSort(item.id)}
                      >
                        <Grid item style={{ marginRight: "5px" }}>
                          {item.label}
                        </Grid>
                        <SortIcon
                          itemId={item.id}
                          sortDir={sortDir}
                          sortField={sortField}
                        />
                      </Grid>
                    ) : (
                      item.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentStatuses.map((row) => (
                <TableRow key={row.statusId}>
                  <TableCell component="th" scope="row">
                    <Typography sx={tableRow}>
                      {row.isSynced ? "ON" : "OFF"}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row" sx={editColor}>
                    <Button
                      onClick={() => navigate(`/queueSetting/${row.statusId}`)}
                      data-testid={`edit-${row.statusId}`}
                      sx={editButton}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={tableRow}>
                      {highlightMatch(row.statusName, searchTerm)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="end" sx={{ margin: "3px" }}>
            <Pagination
              sx={pagination}
              data-auto-test-id="statuses-table-pagination"
              disabled={isLoading}
              count={totalNumberOfPages}
              hideNextButton
              hidePrevButton
              showFirstButton
              showLastButton
              page={page}
              shape="rounded"
              onChange={handleChangePage}
            />
          </Box>
        </TableContainer>
      </ComponentLoader>
    </Box>
  );
}
