import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import OrderGroupingModel from "../../../../../../models/queue/orderGroupingModel ";

interface GroupingOrderListProps {
  readonly items: OrderGroupingModel[];
  readonly handleItemsDrop: any;
  itemTemplate: (
    row: OrderGroupingModel,
    provider: DraggableProvided
  ) => JSX.Element;
}
export default function GroupingOrderList(
  groupingOrderList: Readonly<GroupingOrderListProps>
) {
  const { handleItemsDrop, items, itemTemplate } = groupingOrderList;

  const handleDrop = (e: DropResult) => {
    console.log("🚀 ~ handleDrop ~ e:", e);

    if (!items) return;
    if (!e.destination) return;

    const sourceIndex = e.source.index;
    const destinationIndex = e.destination.index;

    if (sourceIndex === destinationIndex) return;
    let tempData = Array.from(items);
    let [source_data] = tempData.splice(sourceIndex, 1);
    tempData.splice(destinationIndex, 0, source_data);
    handleItemsDrop(tempData);
  };
  if (!items.length) return <></>;
  return (
    <DragDropContext onDragEnd={handleDrop}>
      <Droppable droppableId="orderGroupings">
        {(provider) => (
          <div
            ref={provider.innerRef}
            {...provider.droppableProps}
            data-testid="droppable"
          >
            {items?.map((row: OrderGroupingModel, index) => (
              <Draggable
                index={index}
                draggableId={row.fieldName}
                key={row.fieldName}
              >
                {(provider) => itemTemplate(row, provider)}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
